@import "../../node_modules/bootstrap/scss/bootstrap.scss";
/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 2.75;
  line-height: $base-line-height * $base-font-size * 1.35;
  // Positioning context for the mobile navigation icon
  position: relative;

  @media screen and (max-width: $bootstrap-md) {
    min-height: $spacing-unit * 2;
  }
}

.site-title {
  @include font-size(1.625rem);
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  @include media-query($bootstrap-sm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.nav-title {
  font-family: "Adelle Regular",Georgia,"Times New Roman",serif;  // MU University serif font-stack
  @include font-size(1.15rem);
  -webkit-transition: 0.4s;
  transition: 0.4s;

  @media screen and (min-width: $bootstrap-sm) {
    @include font-size(1.25rem);
  }

  @media screen and (min-width: $bootstrap-md) {
    @include font-size(1.35rem);
  }

  @media screen and (min-width: $bootstrap-lg) {
    @include font-size(1.45rem);
  }
  @media screen and (min-width: $bootstrap-xl) {
    @include font-size(1.55rem);
  }

  &:hover ,  &:visited{
    color: $background-color !important;
  }
}

a.show{
  text-decoration: underline !important;
}

.site-nav {
  position: absolute;
  top: 9px;
  right: $spacing-unit / 2;
  background-color: $background-color;
  border: 1px solid $grey-color-light;
  border-radius: 5px;
  text-align: right;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;

    > svg path {
      fill: $grey-color-dark;
    }
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
  }

  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
      margin-left: auto;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include font-size(1.125rem);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include font-size(0.9375rem);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width:         calc(100% - (#{$spacing-unit} / 2));
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1,
.footer-col-2 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width:         calc(100% - (#{$spacing-unit} / 2));
}

@media screen and (min-width: $on-large) {
  .footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
  }

  .footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
  }
}

@media screen and (min-width: $on-medium) {
  .footer-col {
    float: left;
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1 0 auto;
}

.page-heading {
  @include font-size(2rem);
}

.post-list-heading {
  @include font-size(1.75rem);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: #555;
}

.post-link {
  display: block;
  @include font-size(1.5rem);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title,
.post-content h1 {
  @include font-size(2.625rem);
  letter-spacing: -1px;
  line-height: 1;

  @media screen and (min-width: $on-large) {
    @include font-size(2.625rem);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2, .h2 {
    @include font-size(1.75rem);

    @media screen and (min-width: $on-large) {
      @include font-size(2rem);
    }
  }

  h3, .h3 {
    @include font-size(1.375rem);

    @media screen and (min-width: $on-large) {
      @include font-size(1.625rem);
    }
  }

  h4, .h4 {
    @include font-size(1.125rem);

    @media screen and (min-width: $on-large) {
      @include font-size(1.25rem);
    }
  }
}


.social-media-list {
  display: table;
  margin: 0 auto;
  li {
    float: left;
    margin: 0 5px;
    &:first-of-type { margin-left: 0 }
    &:last-of-type { margin-right: 0 }
    a {
      display: block;
      padding: $spacing-unit / 4;
      border: 1px solid $grey-color-light
    }
    &:hover .svg-icon { fill: currentColor; }
  }
}


/**
 * Grid helpers
 */
.one-half {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}
