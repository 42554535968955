@import "minima";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

  .carousel-indicators{
    max-width: 399px;
    justify-content: space-evenly !important;
  }
  .carousel-indicators img {
    // max-width: 100px;
    width: 100%;
    overflow: hidden;
    display: block;
  }

  .carousel-indicators button {
    max-width: 35%;
    height: auto;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
    &:hover{
      outline: 1.5px solid white;
      outline-offset: -4px;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.75);
    }
  }

  .carousel-indicators button:focus {
    outline-offset: 6px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 1000;
  }

  .carousel-control-prev {
    left: 25.5%;
  }
  .carousel-control-next {
    right: 18.8%;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon{
      width: 1.5rem !important;
      height: 1.5rem !important;
      background-color: $MU-red-vary !important;
  }

 .carousel-slide-img{
  max-height: 675px;
 }

 .carousel-caption{
   left: 75%;
 }

 .carousel-caption h2{
   @include font-size(1.5rem);
 }

 @media only screen and (max-width: 768px){
  .carousel-indicators{
  flex-direction: column;
}
  .carousel-control-next {
    right: 0%;
  }
}
